<template>
  <b-row class="justify-content-center mb-4">
    <b-col md="5">
      <b-card class="shadow-sm">
        <b-list-group>

          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Informations sur la copropriété</span>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Copropriété</h6>
            {{ copropriete.name }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Administateur</h6>
            {{ copropriete.societe_syndic === null ? copropriete.societe_syndic.manager.name : 'Non défini' }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Société Syndic</h6>
            {{ copropriete.societe_syndic.company_name }}
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <h6>Modèle économique</h6>
            <b-badge
              pill
              :variant="`light-${modeleEconomiqueSocieteVariant(copropriete.modele_economique.title)}`"
            >
              {{ copropriete.modele_economique.title }}

            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-card>

    </b-col>
    <b-col md="7">
      <b-row>
        <b-col
          md="6"
          class="mb-3"
        >
          <statistic-card-horizontal
            icon="UsersIcon"
            color="success"

            statistic="130"
            statistic-title="Membres copropriété"
          />
        </b-col>
        <b-col
          md="6"
        >
          <statistic-card-horizontal
            icon="HomeIcon"
            color="info"

            statistic="13"
            statistic-title="Sociétés prestataires"
          />
        </b-col>
        <b-col
          md="6"
        >
          <statistic-card-horizontal
            icon="ListIcon"
            color="warning"

            statistic="13"
            statistic-title="Immeubles"
          />
        </b-col>
        <b-col
          md="6"
        >
          <statistic-card-horizontal
            icon="ListIcon"
            color="primary"

            statistic="203"
            statistic-title="Villas"
          />
        </b-col>
      </b-row>

    </b-col>
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <div class="bg-light shadow-lg p-1 rounded mb-2">
            Staff
          </div>

          <b-card
            no-body
            class="card-company-table"
          >
            <b-table
              :items="staffs.users"
              responsive
              :fields="fieldsUser"
              class="mb-0"
              show-empty
            >
              <template #table-busy>
                <div class="d-flex justify-content-center mb-3">
                  <b-spinner
                    style="width: 2rem; height: 2rem;"
                    variant="primary"
                    label="Large Spinner"
                    type="grow"
                    class="mt-2"
                  />
                  <span class="sr-only">Chargement en cours...</span>
                </div>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  <b-img
                    fluid
                    :style="{ width: '50%' }"
                    :src="require('@/assets/images/no_result.gif')"
                  />
                </div>
              </template>
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(roles)="data">

                <b-badge
                  v-for="item in data.item.roles"
                  :key="`jour-${item.id}`"
                  pill
                  class="ml-1"
                  variant="light-primary"
                >
                  {{ item.title }}

                </b-badge>
              </template>

            </b-table>
          </b-card>
        </b-col>

        <b-col md="6">
          <div class="bg-light shadow-lg p-1 rounded mb-2">
            Sociétés prestataires
          </div>
          <b-card
            no-body
            class="card-company-table"
          >
            <b-table
              :items="tableData"
              responsive
              :fields="fields"
              class="mb-0"
              show-empty
            >
              <template #table-busy>
                <div class="d-flex justify-content-center mb-3">
                  <b-spinner
                    style="width: 2rem; height: 2rem;"
                    variant="primary"
                    label="Large Spinner"
                    type="grow"
                    class="mt-2"
                  />
                  <span class="sr-only">Chargement en cours...</span>
                </div>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  <b-img
                    fluid
                    :style="{ width: '50%' }"
                    :src="require('@/assets/images/no_result.gif')"
                  />
                </div>
              </template>
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(roles)="data">

                <b-badge
                  v-for="item in data.item.roles"
                  :key="`jour-${item.id}`"
                  pill
                  class="ml-1"
                  variant="light-primary"
                >
                  {{ item.libelle }}

                </b-badge>
              </template>

            </b-table>
          </b-card>
        </b-col>
        <b-col md="12">
          <div class="bg-light shadow-lg p-1 rounded mb-2">
            Membres de la copropriété
          </div>
          <b-card
            no-body
            class="card-company-table"
          >
            <b-table
              :items="gestionCoproprietes.users"
              responsive
              :fields="fieldsUser"
              class="mb-0"
              show-empty
            >
              <template #table-busy>
                <div class="d-flex justify-content-center mb-3">
                  <b-spinner
                    style="width: 2rem; height: 2rem;"
                    variant="primary"
                    label="Large Spinner"
                    type="grow"
                    class="mt-2"
                  />
                  <span class="sr-only">Chargement en cours...</span>
                </div>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  <b-img
                    fluid
                    :style="{ width: '50%' }"
                    :src="require('@/assets/images/no_result.gif')"
                  />
                </div>
              </template>
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(roles)="data">

                <b-badge
                  v-for="item in data.item.roles"
                  :key="`jour-${item.id}`"
                  pill
                  class="ml-1"
                  variant="light-primary"
                >
                  {{ item.title }}

                </b-badge>
              </template>

            </b-table>
          </b-card>
        </b-col>
      </b-row>

    </b-col>
  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import {
  BCard, BBadge, BRow, BCol, BListGroup, BListGroupItem, BTable, BSpinner, BImg,

} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useCoproprietes from '@/composables/coproprieteService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useGestionCoproprietes from '@/composables/gestionCoproprieteService'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BTable,
    BSpinner,
    BImg,
    StatisticCardHorizontal,
  },
  directives: {
    Ripple,
  },

  setup() {
    const {
      getCoproprieteById, copropriete,
    } = useCoproprietes()
    const {
      getCoproprieteStaffs, staffs, getCoproprieteWithUsersAndRolesInEachCoproprietes, gestionCoproprietes,
    } = useGestionCoproprietes()
    const tableData = ref([])
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'copropriete', label: 'Copropriété' },
      { key: 'roles', label: 'Rôles' },
    ]
    const modeleEconomiqueSocieteVariant = status => {
      if (status === 'Abonnement') return 'primary'
      if (status === 'Commission') return 'danger'
      return 'primary'
    }
    const fieldsUser = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Nom' },
      { key: 'roles', label: 'Rôles' },
    ]
    onMounted(async () => {
      await getCoproprieteById(router.currentRoute.params.id)
      await getCoproprieteStaffs(router.currentRoute.params.id)
      await getCoproprieteWithUsersAndRolesInEachCoproprietes(router.currentRoute.params.id)
      console.log(copropriete);
    })

    return {
      tableData,
      fields,
      copropriete,
      modeleEconomiqueSocieteVariant,
      staffs,
      getCoproprieteStaffs,
      fieldsUser,
      gestionCoproprietes,

    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
